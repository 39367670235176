module.exports = {
  locations: [
    {
      key: "location-dorrigo",
      title: "Dorrigo Bookings",
      days: "Tuesdays and Thursdays",
      details: [
        { title: "location", value: "Dorrigo Health and Wellbeing" },
        {
          title: "address",
          value: "Cork Trust Medical Centre, 1 Gangara Ave, Dorrigo",
        },
        {
          title: "phone",
          value: "0429 453 806 (Texts welcome)",
          link: "tel:0429453806",
        },
        { title: "landline", value: "6655 8714", link: "tel:66558714" },
        { title: "fax", value: "6657 2629" },
        {
          title: "email",
          value: "melaniejanecouper@gmail.com",
          link: "mailto:melaniejanecouper@gmail.com",
        },
      ],
    },
    {
      key: "location-bellingen",
      title: "Bellingen Bookings",
      days: "Wednesday",
      details: [
        { title: "location", value: "Three Rivers Health" },
        { title: "address", value: "9 Church St, Bellingen" },
        {
          title: "phone",
          value: "6655 0663 (Three Rivers Health)",
          link: "tel:66550663",
        },
        { title: "fax", value: "02 6655 0520", link: "tel:0266550520." },
      ],
    },
    {
      key: "location-coffs",
      title: "Coffs Harbour Bookings",
      days: "Mondays",
      details: [
        { title: "location", value: "Coffs Harbour Women’s Health Centre" },
        { title: "address", value: "53 Little St, Coffs Harbour" },
        { title: "phone", value: "6652 8111", link: "tel:66528111" },
        { title: "fax", value: "02 6652 8778", link: "tel:0266528778" },
        {
          title: "website",
          value: "https://genhealth.org.au/",
          link: "https://genhealth.org.au/",
        },
      ],
    },
    {
      key: "location-thora",
      title: "Thora Bookings",
      days: "By appointment",
      details: [
        { title: "location", value: "Private Residence" },
        { title: "address", value: "421 Summervilles Rd, Thora" },
        {
          title: "phone",
          value: "0429 453 806 (Texts welcome)",
          link: "tel:0429453806",
        },
        { title: "landline", value: "6655 8714", link: "tel:66558714" },
        {
          title: "email",
          value: "melaniejanecouper@gmail.com",
          link: "mailto:melaniejanecouper@gmail.com",
        },
      ],
    },
  ],
}
