/** @jsx jsx */
import React from "react"
import { jsx } from "theme-ui"
import styled from "@emotion/styled"
import { Card, Flex, Heading, Text, Link } from "rebass"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Dl = styled.dl`
  ${({ theme }) => `
  color: white;
  font-size: ${theme.fontSizes[1]}px;
  font-family: ${theme.fonts.body};
  dt {
    float: left;
    clear: left;
    margin-right: 8px;
    font-weight: ${theme.fontWeights.body};
    text-transform: capitalize;
  }
  dd {
    margin-bottom: ${theme.space[2]}px;
    font-weight: ${theme.fontWeights.lessBold};
  }
`}
`

const StyledLink = styled(Link)`
  color: white;
  text-decoration: none;
  :hover {
    text-decoration: underline;
  }
`

export const ContactCard = ({ title, days, details, ...props }) => {
  const { calendar } = useStaticQuery(graphql`
    query {
      calendar: file(relativePath: { eq: "calendar.png" }) {
        childImageSharp {
          fixed(height: 64) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)
  return (
    <Card p={3} textAlign="left" minHeight="290px" {...props}>
      <Flex mb={3}>
        <Img
          alt="logo"
          fixed={calendar.childImageSharp.fixed}
          fadeIn={false}
          sx={{
            mr: 3,
          }}
        />
        <Flex flexDirection="column" justifyContent="space-between" py={2}>
          <Heading color="white" fontSize={3} fontFamily="body">
            {title}
          </Heading>
          <Text fontFamily="body" fontStyle="italic" color="white">
            {days}
          </Text>
        </Flex>
      </Flex>
      <Dl>
        {details.map(({ title, value, link }) => (
          <>
            <dt>{title}:</dt>
            <dd>
              {link ? <StyledLink href={link}>{value}</StyledLink> : value}
            </dd>
          </>
        ))}
      </Dl>
    </Card>
  )
}
