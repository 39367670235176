/** @jsx jsx */
import React from "react"
import { jsx } from "theme-ui"
import { Helmet } from "react-helmet"
import { Tiles } from "@rebass/layout"
import { Header } from "../components/header"
import { Footer } from "../components/footer"
import { Container } from "../components/container"
import data from "../data/contact"
import { ContactCard } from "../components/contact-card"

export default () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>Melanie Couper Physiotherapy | Contact</title>
      </Helmet>
      <Header />
      <Container>
        <Tiles columns={[1, 2]}>
          {data.locations.map((location, index) => (
            <ContactCard
              {...location}
              bg={
                index !== 0 && index !== data.locations.length - 1
                  ? "primary"
                  : "jade"
              }
            />
          ))}
        </Tiles>
      </Container>
      <Footer />
    </React.Fragment>
  )
}
