import React from "react"
import styled from "@emotion/styled"
import { Box } from "rebass"

const StyledBox = styled(Box)`
  max-width: 900px;
  margin: 0 auto;
  min-height: calc(100% - 320px);
  padding-top: ${({ theme }) => theme.space[4]}px;
  padding-bottom: ${({ theme }) => theme.space[4]}px;
`
export const Container = ({ children }) => (
  <StyledBox>
    <Box px={3} py={[0, 0, 5]}>
      {children}
    </Box>
  </StyledBox>
)
